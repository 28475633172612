import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function usePagesList() {
    const isPagesFormSidebarActive = ref(false)
    const pagesData = ref(null);
    
    const toast = useToast()
    const refCatListTable = ref(null);
    const tableColumns = [

        { key: 'title', label: 'Title', sortable: false },
        { key: 'slug', label: 'Slug', sortable: false },


        { key: 'is_active', label: 'Status', sortable: false },
        { key: 'action', label: '#', sortable: false },


    ]
    const perPage = ref(10)
    const totalPages= ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    
    const dataMeta = computed(() => {
        const localItemsCount = refCatListTable.value ? refCatListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPages.value,
        }
    })
    
    const refetchData = () => {
        refCatListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData()
    })
    const fetchData = (ctx, callback) => {
        const offset = perPage.value*(currentPage.value-1);
       
        store
          .dispatch('app-pages/fetchData', {
            limit: perPage.value,
            offset:offset,
            keywords: searchQuery.value,
         
          })
          .then(response => {
           
            const  cats  = response.data.data
            
            const total = response.data.total?response.data.total:cats.length;
            callback(cats)
            totalPages.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Category' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    const resolveCatStatusVariant = status => {
       
        if (status) return 'success'
        if (!status ) return 'secondary'
        return 'primary'
    }
    const resolveCatStatusnameVariant = status => {
        console.log('status',status);
        if (status) return 'Active'
        if (!status) return 'Inactive'
        return 'primary'
    }
    const updateData = (data)=>{

        pagesData.value = data;
        isPagesFormSidebarActive.value = true;
    }
    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-pages/deleteData',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Pages",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
      
    return {
        isPagesFormSidebarActive,
        pagesData,
        refCatListTable,
        tableColumns,
        perPage,
        totalPages,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        statusFilter,
        dataMeta,
        refetchData,
        fetchData,
        resolveCatStatusVariant,
        resolveCatStatusnameVariant,
        updateData,
        deleteData
    

    }
}